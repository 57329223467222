@import 'variable';

@font-face {
  font-family: 'UbuntuM';
  src: local('Ubuntu'),
    url('./fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'UbuntuB';
  src: local('Ubuntu Bold'),
    url('./fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu Regular'),
    url('./fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

html, body {
  margin: 0;
  height: 100%;
}

body {
  background-color: white;
  margin: 0;
  padding: 0px;
  font-family: 'Ubuntu','UbuntuM', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-body;
  overflow: hidden;
}

.ant-col {
  font-family: 'Ubuntu','UbuntuM', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

#root {
  white-space: pre-line;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  background-color: $white !important;
  padding: 0px !important;
  height: auto!important;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
  color: $color-black;
}

.wiwHeader {
  line-height: normal;
  margin: $margin-layot;
}

.wiwHeader button, .pageName {
  width: 100%;
  border: $border-button;
  color: $color-black;
  font-family: 'UbuntuB';
  text-transform: uppercase;
  padding-left: 0px;
  padding-right: 0px;
  text-overflow: clip;
  overflow: hidden;
  border-radius: $button-radius;
  height: $control-button-height;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color:  $white;
}

.wiw-input {
  border: $border-button;
  font-family: 'UbuntuM';
  border-radius: $button-radius;
  background-color: $white;
  padding: $input-padding;
  color: $color-black;
  margin-bottom: $margin-layot;
}

.wiw-input::placeholder {
  color: $color-black;
  opacity: 0.8;
}

.wiw-input .ant-input-prefix {
  margin-inline-end: 0px;
}

.picture-input {
  display: block;
  padding: 0px;;
}

.picture-input .ant-upload {
  display: block;
}

.picture-input .userImage {
  margin: $margin-default $margin-default $margin-default $input-padding;
}

.lng-radio {
  text-align: left;
}
.lng-radio .ant-radio-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  flex-wrap: wrap;
}

.lng-radio .ant-radio-button-wrapper {
  border: $border-button;
  color: $color-black;
  border-radius: $button-radius;
  height: $btn-radio-lng-height;
  width: $btn-radio-lng-height;
  line-height: 36px;
  text-transform: uppercase;
  padding: 0px;
  text-align: center;
  margin: $margin-default;
  opacity: 0.3;
  font-family: 'UbuntuB';
}

.lng-radio .ant-radio-button-wrapper-checked, .lng-radio .ant-radio-button-wrapper-checked:hover {
  opacity: 1;
  border: $border-button;
  color: $color-black;
}

.lng-radio .ant-radio-button-wrapper::before {
  display: none;
}
.storyTitle {
  margin: $margin-layot;
}

.button_border {
  border: $border-button;
  color: $color-black;
}

.button-disable {
  color: $color-button-disable !important;
  cursor: not-allowed;
}

.wiwHeader button.ant-btn:hover, .button_border:hover {
  color: $color-black-hover !important;
}

.wiwHeader button.ant-btn.pageName:hover {
  color: $color-black !important;
}

.wiwHeader .pageName {
  cursor: default;
}

.wiwHeader .ant-btn.ant-btn-icon-only {
  width: 100%;
}
.nav-icon {
  color: $color-black;
  width: 30px;
  height: 30px;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.content-text {
  font-family: "Ubuntu" !important;
  font-size: 16px;
  margin: 0px $margin-layot;
  height: 100%;
  position: relative;
}

.content-layot {
  overflow-y: auto;
  position: fixed;
  top: 150px;
  left: 0px;
  right: 0px;
  overscroll-behavior-y: none;
  z-index: 1;
  color: $color-black;
}

.ant-layout {
  background-color: $white;
  font-family: 'Ubuntu','UbuntuM', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-body;
}

footer.ant-layout-footer {
  background-color: $white !important;
  padding: 0 $margin-layot $margin-layot $margin-layot !important;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
}
.clear {
  clear: both;
}

header.login_page_header {
  text-align: center;
  height: auto  !important;
  line-height: 24px !important;
  padding: 24px !important;
}

.roundImage {
  border-radius: 50%;
}

.userImage {
  display: block;
}

.userVote .userImage, .profile-user-control .userImage, .picture-input .userImage {
  width: $user-vote-height;
  height: $user-vote-height;
}

.profile-user-control {
  margin-top: $margin-layot;
}

.profile-user-control-button {
  height: $user-vote-height;
}

.profile-user-control-button .nav-icon {
  margin-left: $margin-default;
  margin-right: $margin-default;
}

.profile-user-name {
  font-weight: bold;
}

.profile-bio {
  margin: 0 $margin-layot $margin-layot $margin-layot;
}

.addCommentPage {
  height: 100%;
}

.profile-settings {
  margin: $margin-layot $margin-layot $margin-layot;
}

.addCommentText.ant-input {
  border: none;
  font-size: $font-size-body;
  color: $color-black;
  height: calc(100% - $user-vote-height - $control-button-height - 3 * $margin-default);
  margin-top: $margin-default;
}

.editor-wrapper {
  height: calc(100% - $user-vote-height - $control-button-height - 3 * $margin-default - 30px);
}

textarea.addCommentText:focus, button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.userVote {
  height: $user-vote-height;
  /*margin-bottom: $margin-layot;*/
}

*,*:focus,*:hover{
  outline:none;
}

.reaction_content {
  display: block;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  word-wrap: break-word;
  position: relative;
}

.reaction_content img {
  max-width: 100%;
}

.reaction_content p {
  margin: $margin-default 0px
}

.reaction_read {
  position: absolute;
  bottom: 30px;
  left: 0px;
  right: 0px;
  text-align: center;
}

.story_user_scope {
  width: 100%;
  height: 30px;
  border: $border-button;
  border-radius: $button-radius;
  margin-top: 10px;
  text-align: center;
  color: $color-black;
  font-size: 24px;
  display: block;
}

.story_user_scope.size_big {
  width: 160px;
  height: auto;
  text-transform: uppercase;
  color: $white;
  word-break: break-word;
  overflow-wrap: break-word;
  padding: $margin-layot;
}

.scope1 {
  background-color: $voting-red;
}
.scope2 {
  background-color: $voting-red-light;
}
.scope3 {
  background-color: $voting-orange;
}
.scope4 {
  background-color: $voting-grey;
}
.scope5 {
  background-color: $voting-light-light-green;
}
.scope6 {
  background-color: $voting-light-green;
}
.scope7 {
  background-color: $voting-green;
}
.sceleton-reaction-load {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: $white;
  z-index: 1;
}

.story_add_title, .story_add_title:focus {
  background-color: $header-color;
  border: none;
  font-size: 16px;
  font-family: "UbuntuM" !important;
  text-align: center;
}

.story-add-loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  background-color: rgba($white, .75);
  z-index: 10;
  bottom: 0px;
}
.rdw-image-modal-size {
  display: none !important;
}
.control-panel-reaction {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: $white;
  z-index: 2;
}
.control-panel-reaction-like, .control-panel-reaction-translate {
  display: inline-flex;
  align-items: center;  
}
.control-panel-reaction-translate {
  font-size: 12px;
  /*color: $color-black-hover;*/
}
.control-panel-reaction-translate.active-translate, .control-panel-reaction-translate.active-translate svg {
  color: $voting-light-green;
}
.control-panel-reaction-description {
  text-align: center;
  padding: $margin-default;
}
.is-like-icon {
  color: $voting-red;
}

